<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{ $t('review.subtitle') }}</v-card-title>
      <v-card-text>
        <v-row v-if="!reviewed" class="text-center">
          <v-col cols="4">
            <img src="https://burgerbreton.net/cdn/image/website/icon/bad.png" :title="$t('review.note.bad')" class="v-chip--clickable" @click.stop="review('bad')">
          </v-col>
          <v-col cols="4">
            <img src="https://burgerbreton.net/cdn/image/website/icon/average.png" :title="$t('review.note.average')" class="v-chip--clickable" @click.stop="review('average')">
          </v-col>
          <v-col cols="4">
            <img src="https://burgerbreton.net/cdn/image/website/icon/good.png" :title="$t('review.note.good')" class="v-chip--clickable" @click.stop="review('good')">
          </v-col>
        </v-row>
        <div v-else>
          {{ $t('review.thankyou') }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        reviewed: false
      };
    },
    methods: {
      review (note) {
        this.reviewed = true;
      }
    }
  };
</script>
<style lang="scss" scoped>
  img {
    width: 80%;
  }
</style>
